import { useState } from "react";
import "../../Heder.css";

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <header className="total-header">
      {/* topHeader */}
      <div className="top-header">
        <div className="top-div">
          <div className="top-right">
            <ul className="top-right-ul">
              <li className="top-right-li">
                <div>로그인</div>
              </li>
              <li className="top-right-li-li">|</li>
              <li className="top-right-li">
                <div>회원가입</div>
              </li>
              <li className="top-right-li-li">|</li>
              <li className="top-right-li">
                <div>언어</div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {/* bottomHeader */}
      <div className="bottom-header">
        <div className="bottom-context">
          <div>FOR CAT&MUNG CENTER</div>
          <nav>
            <div className="hamburger" onClick={toggleMenu}>
              <div className={menuOpen ? "bar open" : "bar"}></div>
              <div className={menuOpen ? "bar open" : "bar"}></div>
              <div className={menuOpen ? "bar open" : "bar"}></div>
            </div>
            <ul className={menuOpen ? "nav-links open" : "nav-links"}>
              <li>소개</li>
              <li>후원</li>
              <li>동물보호</li>
              <li>소식</li>
              <li style={{ marginRight: 0 }}>바로 후원하기</li>
            </ul>
          </nav>
        </div>
      </div>
    </header>
  );
};

export default Header;
