import React, { useEffect, useState } from "react";
import "../../DonationPage.css";
import { Step1, Step2, Step3, Step4 } from "../../components/DonationPage";
import Header from "../../components/Header/header";
import { useLocation, useNavigate } from "react-router-dom";

const DonationPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [data, setData] = useState({
    pay_name: "",
    pay_type: "",
    email: "",
    phone: "",
    pay_amount: 0,
    merchant_uid: "",
    imp_uid: "",
    pay_method: "",
    user_type: "",
  });

  const getCurrentStep = () => {
    if (location.pathname === "/") return step;
    if (location.pathname === "/success/3") return 3;
    if (location.pathname === "/success/4") return 4;
    return 1;
  };

  const currentStep = getCurrentStep();

  const handleData = (object) => {
    setData((prevData) => ({
      ...prevData,
      ...object,
    }));
  };

  // 데이터 확인용
  // useEffect(() => {
  //   console.log(data);
  // }, [data]);

  // 스텝3,4 url 접근 금지
  useEffect(() => {
    if (
      (location.pathname === "/success/3" ||
        location.pathname === "/success/4") &&
      !data.pay_name
    ) {
      navigate("/");
    }
  }, [location.pathname, data, navigate]);

  // 다음 버튼 핸들러
  const handleNext = () => {
    if (currentStep === 1) {
      setStep(2);
    } else if (currentStep === 2) {
      navigate("/success/3");
    } else if (currentStep === 3) {
      navigate("/success/4");
    } else if (currentStep === 4) {
      navigate("/");
      setStep(1);
    }
  };

  // 이전 버튼 핸들러
  const handleBack = () => {
    if (currentStep === 2) {
      setStep(1);
    } else if (currentStep === 3) {
      navigate("/");
      setStep(2);
    } else if (currentStep === 4) {
      navigate("/success/3");
    }
  };

  // 섹션별 렌더링
  const renderSection = () => {
    if (location.pathname === "/") {
      if (step === 1) {
        return (
          <Step1 handleData={handleData} handleNext={handleNext} data={data} />
        );
      } else if (step === 2) {
        return (
          <Step2
            data={data}
            handleData={handleData}
            handleNext={handleNext}
            handleBack={handleBack}
          />
        );
      }
    } else if (location.pathname === "/success/3") {
      return (
        <Step3
          data={data}
          handleData={handleData}
          handleNext={handleNext}
          handleBack={handleBack}
        />
      );
    } else if (location.pathname === "/success/4") {
      return <Step4 data={data} handleNext={handleNext} />;
    }
    return <Step1 handleData={handleData} data={data} />;
  };

  return (
    <div className="donation-page">
      <Header />
      {/* <header className="header">
        <div className="logo">FOR CAT&MUNG CENTER</div>
      </header> */}

      <main>
        <h1 className="title">후원신청</h1>
        <p className="subtitle">아이들의 큰 힘이 되어주세요</p>

        {/* 단계 표시 바 */}
        <div className="steps">
          <div className={currentStep === 1 ? "step active" : "step"}>
            STEP 1<br />
            후원정보입력
          </div>
          <div className={currentStep === 2 ? "step active" : "step"}>
            STEP 2<br />
            후원자정보
          </div>
          <div className={currentStep === 3 ? "step active" : "step"}>
            STEP 3<br />
            결제
          </div>
          <div className={currentStep === 4 ? "step active" : "step"}>
            STEP 4<br />
            후원 완료
          </div>
        </div>
        {/* Step 랜더링 */}
        <section className="donation-info">{renderSection()}</section>
      </main>

      <footer className="footer">
        <p>
          - 문의사항이 있다면 이메일 forcatmungcenter@naver.com 으로
          문의해주세요
        </p>
        <p>
          단체명: (사)포캣멍센터 | 주소: 경기도 안산시 상록구 광덕1로 369, 2층
          205호(이동, 센타프라자)
          <br />
          사업자번호: 889-82-00563 | 대표: 양인영 | 전화: 0507-1307-9338
        </p>
        <p>Copyright © 2024 포켓멍. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default DonationPage;
