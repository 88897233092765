const Step3 = ({ hadleData, handleNext, handleBack, data }) => {
  const handleDataSend = () => {
    handleNext();
  };
  return (
    <div>
      <div className="donation-amount"></div>
      <button
        className="next-btn"
        onClick={() => handleDataSend()}
        style={{ marginBottom: "30px" }}
      >
        결제 완료하기 &gt;
      </button>
    </div>
  );
};

export default Step3;
