const Personal = () => {
  return (
    <div style={{margin:20}}>
    <h1 style={{ display: "flex", justifyContent: "center" }}>개인정보처리방침</h1>
    <h3>1. 개인정보의 처리 목적</h3>
    <p>
      포캣멍센터는 개인정보를 다음의 목적을 위해 처리합니다.
      처리한 개인정보는 다음의 목적 이외의 용도로는 사용되지 않으며
      이용 목적이 변경될 시에는 사전동의를 구할 예정입니다.
    </p>
    <ol>
      <li style={li}>회원가입 및 유지</li>
      <li style={li}>비정기 또는 일시 후원</li>
      <li style={li}>포캣멍센터 활동내용 공유 </li>
      <li style={li}>정보 문의와 민원 상담</li>
      <li style={li}>행사와 활동 참여: 행사 정보 제공 및 참여기회 제공 등</li>
    </ol>

    <h3>2. 처리하는 개인정보 항목</h3>
    <ol>
      <li>회원가입 및 유지</li>
        <p>
          포캣멍센터는 회원 기본정보(이름, 성별, 나이, 직업),
          후원정보(주민등록번호, 후원방법, 후원 약정액, 후원 계좌 또는 신용카드 번호, 신용카드 유효기간),
          연락 및 의사소통을 위한 정보(우편물 수령 주소, 휴대전화번호, 이메일, 페이스북 계정, 트위터 계정)를 수집하고 있습니다.
          단, 만 20세 미만 미성년자는 법정대리인(보호자)의 정보를 요구할 수 있습니다.
        </p>
        <li>비정기 또는 일시 후원</li>
        <p>
          포캣멍센터에 비정기 또는 일시로 후원하는 후원자는 기본정보(이름 또는 단체명),
          후원정보(주민등록번호 또는 사업자등록번호, 후원계좌/신용카드번호/휴대전화번호, 신용카드 유효기간),
          연락 및 의사소통을 위한 정보(우편물 수령주소, 전화번호, 이메일)를 수집하고 있습니다.
        </p>
        <li>포캣멍센터 활동내용 공유</li>
        <p>
          포캣멍센터는 회원이 아니더라도 활동 소식을 정기적으로 받아보기를 원하는 개인 또는 단체의 정보를 수집하고 있습니다.
          이는 이용자의 동의에 따라 자발적으로 제공한 정보에 한하며, 이름과 이메일 이외의 어떤 정보도 수집하지 않습니다.
        </p>
        <li>정보 문의와 민원 상담</li>
        <p>
         포캣멍센터는 회원과 시민들이 상담을 요청하거나 제보를 하는 경우 의사소통을 원활히 하기 위해
         이름, 연락처(휴대전화번호 또는 이메일) 등의 정보를 수집하여 처리하고 있습니다.
        </p>
        <li>행사와 활동 참여</li>
        <p>
          포캣멍센터가 주최 혹은 주관하는 행사와 설문조사와 같은 활동에 회원과 시민들이 참여를 원할 경우 내용 공유와 진행을 위해
          개인정보 수집에 대한 동의를 얻은 후 이름, 휴대전화번호, 이메일 등의 정보를 요구할 수 있습니다.
        </p>
    </ol>

    <h3>3. 개인정보의 처리 및 보유 기간</h3>
    <p style={{lineHeight:1.5}}>
      1. 본회는 본 법인은 「민법」제3장 및 「농림축산식품부장관 및 그 소속청장
      소관 비영리법인의 설립 및 감독에 관한 규칙」에 의거 생명존중의 정신으로
      지역사회에서 동물복지 증진을 위한 활동으로 사람과 동물이 함께하는 행복한
      공존에 이바지힘을 목적으로 한다.
    </p>

    <p>2. 각각의 개인정보 처리 및 보유 기간은 다음과 같습니다.</p>
      <ol>
        <li style={li}>회원 정보</li>
          <p >보유기간: 회원 탈퇴시까지</p>
          <p>보유사유: 회원약관 동의</p>
        <li>물품 후원자의 기부금영수증 발급</li>
          <p>보유기간: 5년</p>
          <p>보유사유: 법인세법 제112조 2</p>
        <li>자원활동 신청시</li>
          <p>보유기간: 삭제 요청시까지</p>
          <p>보유사유: 신청자 동의</p>          
      </ol>

    <h3>4. 개인정보의 제3자 제공</h3>
    <p style={{lineHeight:1.5}}>
      포캣멍센터는 정보 주체의 개인정보를 제1조(개인정보의 처리 목적)에서 명시한 범위 내에서만 처리하며 정보 주체의 동의, 법률의 특별한 규정 등
      개인정보 보호법 제17조 및 제18조에 해당하는 경우에만 개인정보를 제3자에게 제공합니다.
    </p>

    <h3>5. 개인정보처리 위탁</h3>
    <ol>
    <li>
      포캣멍센터는 원활한 개인정보 업무처리를 위하여 다음과 같이 개인정보 처리업무를 위탁하고 있습니다.
    </li>
    <table style={table}>
    <tr>
        <td style={td}>㈜휴먼소프트웨어</td>
        <td style={td}>회원DB 관리 및 후원금 결제정보 관리</td>
    </tr>
    <tr>
      <td style={td}>금융결제원</td>
      <td style={td}>정기 후원금 CMS 자동이체 납입 및 내역 관리</td>
    </tr>
    <tr>
      <td style={td}>㈜나이스정보통신</td>
      <td style={td}>신용카드, 실시간 계좌이체 후원금 결제</td>
    </tr>
    <tr>
      <td style={td}>로젠택배</td>
      <td style={td}>상품 배송</td>
    </tr>
</table>
  <li style={li}>
    포캣멍센터는 위탁계약 체결시 개인정보 보호법 제26조에 따라 위탁업무 수행목적 외 개인정보 처리금지, 기술적․관리적 보호조치, 재위탁 제한, 수탁자에 대한 관리․감독, 손해배상 등
    책임에 관한 사항을 계약서 등 문서에 명시하고, 수탁자가 개인정보를 안전하게 처리하는지를 감독하고 있습니다.
  </li>
  <li style={li}>
    위탁업무의 내용이나 수탁자가 변경될 경우에는 지체없이 본 개인정보 처리방침을 통하여 공개하도록 하겠습니다.
  </li>
</ol>


    <h3>6. 정보주체의 권리, 의무 및 그 행사방법</h3>
    <p>이용자는 개인정보 주체로서 다음과 같은 권리를 행사할 수 있습니다.</p>
    <ol>
    <li style={li}>
      정보 주체는 포캣멍센터에 대해 언제든지 개인정보 열람 요구, 오류 등이 있을 경우 정정 요구, 삭제요구, 처리정지 요구의 개인정보 보호 관련 권리를 행사할 수 있습니다.
    </li>
    <li style={li}>
      제1항에 따른 권리 행사는 포캣멍센터에 대해 개인정보 보호법 시행규칙 별지 제8호 서식에 따라 서면, 전자우편, 모사전송(FAX) 등을 통해 하실 수 있으며 포캣멍센터는 이에 대해 지체없이 조치하겠습니다.
    </li>
    <li style={li}>
      정보주체가 개인정보의 오류 등에 대한 정정 또는 삭제를 요구한 경우에는 포캣멍센터는 정정 또는 삭제를 완료할 때까지 당해 개인정보를 이용하거나 제공하지 않습니다.
    </li>
    <li style={li}>
      제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다. 이 경우 개인정보 보호법 시행규칙 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다.
    </li>
    </ol>

    <h3>7. 처리하는 개인정보의 항목</h3>
    <p>포캣멍센터는 다음의 개인정보 항목을 처리하고 있습니다.</p>
    <ol>
      <li style={li}>회원/후원자/이용자 식별 : 이름, 생년월일, 이메일, 휴대전화번호</li>
      <li style={li}>후원금 후원 요청 : 후원계좌, 신용카드 결제 정보, 휴대전화번호</li>
      <li style={li}>활동내용 공유 경로 확보 : 이메일, 휴대전화번호, 우편물 수령 주소, 인스타그램 계정, 구글 계정, 네이버 계정, 카카오 계정</li>
      <li style={li}>기부금 영수증 발급 : 이름, 주민등록번호, 후원내역</li>
    </ol>

    <h3>8. 개인정보의 파기</h3>
    <p style={{lineHeight:1.5}}>포캣멍센터는 원칙적으로 개인정보 처리목적이 달성된 경우에는 지체없이 해당 개인정보를 파기합니다. 파기의 절차, 기한 및 방법은 다음과 같습니다.</p>  
    <li>  
      파기절차
    </li>
    <p style={{lineHeight:1.5}}>
      이용자가 입력한 정보는 목적 달성 후 별도의 DB에 옮겨져(종이의 경우 별도의 서류) 내부 방침 및 기타 관련 법령에 따라 일정기간 저장된 후 혹은 즉시 파기됩니다.
      이 때, DB로 옮겨진 개인정보는 법률에 의한 경우 외 다른 목적으로 이용되지 않습니다.
    </p>
    <li>  
      파기기한
    </li>
    <p style={{lineHeight:1.5}}>
      이용자의 개인정보는 보유기간이 만료되었거나 개인정보의 처리목적달성, 해당 업무의 폐지 등 그 개인정보가 불필요하게 되었을 때에는 지체없이 파기합니다.
    </p>
    <li>  
      파기방법
    </li>
    <p style={{lineHeight:1.5}}>
      전자적 파일 형태의 정보는 기록을 재생할 수 없는 기술적 방법을 사용합니다. 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.
    </p>

    <h3>9. 개인정보의 안전성 확보 조치</h3>
    <p>포캣멍센터는 개인정보보호법 제29조에 따라 다음과 같이 안전성 확보에 필요한 기술적, 관리적, 물리적 조치를 하고 있습니다.</p>
    <li>  
      개인정보에 대한 접근 제한
    </li>
    <p style={{lineHeight:1.5}}>
      개인정보를 처리하는 데이터베이스시스템에 대한 접근권한의 부여,변경,말소를 통하여 개인정보에 대한 접근통제를 위하여 필요한 조치를 하고 있으며
      침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있습니다.
    </p>
    <li>  
      내부관리계획의 수립 및 시행
    </li>
    <p>
      개인정보의 안전한 처리를 위하여 내부관리계획을 수립하고 시행하고 있습니다.
    </p>
    <li>  
      해킹 등에 대비한 기술적 대책
    </li>
    <p style={{lineHeight:1.5}}>
      포캣멍센터는 해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을 막기 위하여 보안프로그램을 설치하고 주기적인 갱신·점검을 하며
      외부로부터 접근이 통제된 구역에 시스템을 설치하고 기술적/물리적으로 감시 및 차단하고 있습니다.
    </p>
    <li>  
      개인정보의 암호화
    </li>
    <p style={{lineHeight:1.5}}>
      이용자의 개인정보는 비밀번호는 암호화되어 저장 및 관리되고 있어 본인만이 알 수 있으며,
      중요한 데이터는 파일 및 전송 데이터를 암호화하거나 파일 잠금 기능을 사용하는 등의 별도 보안기능을 사용하고 있습니다.
    </p>
    <li>  
      접속기록의 보관 및 위변조 방지
    </li>
    <p>
      개인정보처리시스템에 접속한 기록을 최소 6개월 이상 보관, 관리하고 있으며, 접속기록이 위변조 및 도난, 분실되지 않도록 보안 기능을 사용하고 있습니다.
    </p>
    <li>  
      문서보안을 위한 잠금장치 사용
    </li>
    <p>
      개인정보가 포함된 서류, 보조저장매체 등을 잠금장치가 있는 안전한 장소에 보관하고 있습니다.
    </p>
    <li>  
      비인가자에 대한 출입 통제
    </li>
    <p>
      개인정보를 보관하고 있는 물리적 보관장소를 별도로 두고 이에 대해 출입통제 절차를 수립, 운영하고 있습니다.
    </p>

    <h3>10. 개인정보 보호책임자 및 담당자</h3>
    <p style={{lineHeight:1.5}}>
      포캣멍센터는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여
      아래와 같이 개인정보 보호책임자를 지정하고 있습니다.
    </p>
    <li style={li}>
      개인정보 보호책임자: 사무국장 (0507-1307-9338, forcatmungcenter@naver.com)
    </li>
    <li style={li}>
      개인정보 보호 담당 부서: 사무국
    </li>
    <p style={{lineHeight:1.5}}>
      정보주체께서는 포캣멍센터의 서비스를 이용하시면서 발생한 모든 개인정보 보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을 개인정보 보호책임자 및 담당부서로 문의하실 수 있습니다.
      포캣멍센터는 문의에 대해 지체 없이 답변 및 처리해드릴 것입니다.
    </p>
    
    <h3>11. 권익침해 구제방법</h3>
    <p style={{lineHeight:1.5}}>
      정보주체는 개인정보침해로 인한 구제를 받기 위하여 개인정보분쟁조정위원회, 한국인터넷진흥원 개인정보침해신고센터 등에 분쟁해결이나 상담 등을 신청할 수 있습니다. 이 밖에 기타 개인정보침해의 신고, 상담에 대하여는 아래의 기관에 문의하시기 바랍니다.
    </p>
    <p>
      - 개인정보침해신고센터 (privacy.kisa.or.kr / 국번없이 118)
    </p>
    <p>
      - 개인정보분쟁조정위원회 (www.kopico.go.kr / 1833-6972)
    </p>
    <p>
      - 대검찰청 사이버수사과 (www.spo.go.kr / 국번없이 1301)
    </p>
    <p>
      - 경찰청 사이버안전국 (cyberbureau.police.go.kr / 국번없이 182)
    </p>
    <p style={{lineHeight:1.5}}>
      「개인정보보호법」제35조(개인정보의 열람), 제36조(개인정보의 정정·삭제), 제37조(개인정보의 처리정지 등)의 규정에 의한 요구에 대하여
      공공기관의 장이 행한 처분 또는 부작위로 인하여 권리 또는 이익의 침해를 받은 자는 행정심판법이 정하는 바에 따라 행정심판을 청구할 수 있습니다.
    </p>
    <p>
      ※ 행정심판에 대해 자세한 사항은 법제처 (http://www.moleg.go.kr) 홈페이지를 참고하시기 바랍니다.
    </p>


    <h3>12. 영상정보처리기기 설치․운영</h3>
    <p>포캣멍센터는 아래와 같이 영상정보처리기기를 설치․운영하고 있습니다.</p>
    <ol>
      <li style={li}>영상정보처리기기 설치근거․목적 : 포캣멍센터의 시설안전․화재예방</li>
      <li style={li}>설치 대수, 설치 위치, 촬영 범위 : [건물 로비 등 주요시설물]에 16대 설치, 촬영범위는 주요시설물의 공간을 촬영, [포캣멍 내부]에 3대 설치</li>
      <li style={li}>관리책임자, 담당부서 및 영상정보에 대한 접근권한자 : 사무국장</li>
      <li style={li}>영상정보 촬영시간, 보관기간, 보관장소, 처리방법</li>
        <p style={{margin:0}}>○ 촬영시간 : 24시간 촬영</p>
        <p style={{margin:0}}>○ 보관기간 : 촬영시부터 30일</p>
        <p style={{margin:0}}>○ 보관장소 및 처리방법 : 건물 관리사무소, 포캣멍에서 보관․처리</p>
      <li style={li}>
        정보주체의 영상정보 열람 등 요구에 대한 조치 : 개인영상정보 열람․존재확인 청구서로 신청하여야 하며,
        정보주체 자신이 촬영된 경우 또는 명백히 정보주체의 생명․신체․재산 이익을 위해 필요한 경우에 한해 열람을 허용합니다.
      </li>
      <li style={li}>
        영상정보 보호를 위한 기술적․관리적․물리적 조치 : 내부관리계획 수립, 접근통제 및 접근권한 제한, 영상정보의 안전한 저장․전송기술 적용,
        처리기록 보관 및 위․변조 방지조치, 보관시설 마련 및 잠금장치 설치
      </li>
    </ol>
    
    <h3>13. 개인정보 처리방침의 변경</h3>
      <p style={{lineHeight:1.5}}>
        이 개인정보처리방침은 시행일로부터 적용되며, 법령 및 방침에 따른 변경 내용의 추가, 삭제 및 정정이 있는 경우에는 홈페이지의 '공지사항'을 통해 이전의 개인정보 처리방침을 고지할 것입니다.
      </p>
      <p>- 공고일자: 2025년 1월 1일</p>
  </div>
  );
};
export default Personal;

const table = {
  border: "1px solid black",
  borderCollapse: "collapse", 
  marginTop: "10px"
};

const td = {
  border: "1px solid black", 
  padding: "8px",
};

const li = {
  marginBottom : "3px",
  padding:"3px",
  lineHeight:1.5
}