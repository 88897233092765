const Step4 = ({ data, handleNext }) => {
  return (
    <div>
      <h1>감사합니다.</h1>
      <button className="next-btn" onClick={handleNext}>
        메인화면으로 이동
      </button>
    </div>
  );
};

export default Step4;
