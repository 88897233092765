import PortOne from "@portone/browser-sdk/v2";
import { useEffect, useState } from "react";

const Step2 = ({ handleData, handleNext, handleBack, data }) => {
  const [userType, setUserType] = useState("");
  const [userName, setUserName] = useState("");
  const [userPhone, setUserPhone] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [paymentType, setPaymentType] = useState("");
  const [essential, setEssential] = useState(false);
  const [allCheck, setAllCheck] = useState(false);
  const [personalCheck, setPersonalCheck] = useState(false);
  const [termsCheck, setTermsCheck] = useState(false);
  const [essentialTerms, setEssentialTerms] = useState(false);
  useEffect(() => {
    if (data) {
      setUserName(data.pay_name);
      setUserEmail(data.email);
      setUserPhone(data.phone);
      setPaymentType(data.pay_method);
      setUserType(data.user_type);
    }
  }, [data]);

  //   개인/ 기업 선택
  const handleUserTypeClick = (type) => {
    setUserType(type);
  };
  // 결제 방식
  const handlePaymentType = (method) => {
    setPaymentType(method);
  };
  // 유저 이름 입력
  const handleName = (Name) => {
    setUserName(Name);
  };
  // 휴대폰 입력
  const handlePhone = (phoneNum) => {
    setUserPhone(phoneNum);
  };
  // 이메일 입력
  const handleEmail = (email) => {
    setUserEmail(email);
  };
  //미입력시 브레이크
  const handleDataSend = async () => {
    if (
      userEmail === "" ||
      userPhone === "" ||
      userName === "" ||
      paymentType === "" ||
      userType === ""
    ) {
      setEssential(true);
    } else if (!allCheck) {
      setEssentialTerms(true);
    } else {
      handleData({
        email: userEmail,
        phone: userPhone,
        pay_name: userName,
        pay_method: paymentType,
        user_type: userType,
      });
      try {
        // if (paymentType === "KAKAO_PAY") {
        //   await requestKakaoPay();
        // } else {
        await requestPayment();
        // }
      } catch (error) {
        console.error("결제오류", error);
        alert("결제 에러");
      }
    }
  };
  // 이용약관 체크박스
  const handleAllCheck = (e) => {
    const checked = e.target.checked;
    setAllCheck(checked);
    setPersonalCheck(checked);
    setTermsCheck(checked);
  };
  // 개별 체크로 모두 선택 시 자동으로 전체동의 체크박스 채워짐
  useEffect(() => {
    if (personalCheck && termsCheck) {
      setAllCheck(true);
    } else {
      setAllCheck(false);
    }
  }, [personalCheck, termsCheck]);

  // 포트원 카드/계좌이체 결제요청
  async function requestPayment() {
    const paymentid = `${crypto.randomUUID().replace(/-/g, "")}`;
    // 카카오페이
    if (paymentType === "KAKAO_PAY") {
      const response = await PortOne.requestPayment({
        storeId: process.env.REACT_APP_STORE_ID,
        channelKey: process.env.REACT_APP_KAKAOCHANNEL_KEY,
        paymentId: paymentid,
        orderName: `${data.pay_type}`,
        totalAmount: data.pay_amount,
        currency: "CURRENCY_KRW",
        payMethod: "EASY_PAY",
        redirectUrl: `https://forcatmung.kr/success/3`,
        windowType: {
          pc: "IFRAME",
          mobile: "REDIRECTION",
        },
      });
      if (response.code !== undefined) {
        return alert(response.message);
      } else {
        handleNext();
      }
    } else {
      // 카드 / 계좌이체
      const response = await PortOne.requestPayment({
        storeId: process.env.REACT_APP_STORE_ID,
        channelKey: process.env.REACT_APP_CHANNEL_KEY,
        paymentId: paymentid,
        orderName: `${data.pay_type}`,
        totalAmount: data.pay_amount,
        currency: "CURRENCY_KRW",
        payMethod: `${paymentType}`,
        windowType: {
          pc: "IFRAME",
          mobile: "REDIRECTION",
        },
        redirectUrl: `https://forcatmung.kr/success/3`,
      });
      if (response.code !== undefined) {
        return alert(response.message);
      } else {
        handleNext();
      }
    }
  }

  return (
    <div>
      <h2>후원자 정보</h2>
      <div className="donation-type">
        <button
          className={`type-btn ${userType === "individual" ? "active" : ""}`}
          onClick={() => handleUserTypeClick("individual")}
        >
          개인
        </button>
        <button
          className={`type-btn ${userType === "company" ? "active" : ""}`}
          onClick={() => handleUserTypeClick("company")}
        >
          기업 / 단체
        </button>
      </div>
      <div className="step2-info">
        <div className="step2-part">
          <p className="step2-text">이름</p>
          <input
            type="text"
            id="name"
            placeholder="이름"
            className="step2-input"
            value={userName}
            onChange={(e) => handleName(e.target.value)}
          />
        </div>
        <div className="step2-part">
          <p className="step2-text">휴대폰 번호</p>
          <input
            type="text"
            id="phone"
            maxLength="11"
            placeholder="휴대폰 번호"
            className="step2-input"
            value={userPhone}
            onChange={(e) => handlePhone(e.target.value)}
          />
        </div>
        <div className="step2-part">
          <p className="step2-text">이메일</p>
          <input
            type="email"
            id="email"
            name="email"
            placeholder="이메일"
            value={userEmail}
            className="step2-input"
            onChange={(e) => handleEmail(e.target.value)}
          />
        </div>
      </div>
      <div className="donation-type">
        <button
          className={`type-btn ${paymentType === "CARD" ? "active" : ""}`}
          onClick={() => handlePaymentType("CARD")}
        >
          카드결제
        </button>
        <button
          className={`type-btn ${paymentType === "TRANSFER" ? "active" : ""}`}
          onClick={() => handlePaymentType("TRANSFER")}
        >
          계좌이체
        </button>
        <button
          className={`type-btn ${paymentType === "KAKAO_PAY" ? "active" : ""}`}
          onClick={() => handlePaymentType("KAKAO_PAY")}
        >
          카카오 페이
        </button>
      </div>
      {/* 동의 체크 여부란 */}
      <div className="check-div">
        {/* 전체 동의 */}
        <div>
          <label style={{ color: "white" }}>&#42;</label>
          <input
            className="checkBox"
            id="allCheck"
            type="checkbox"
            checked={allCheck}
            onChange={handleAllCheck}
          ></input>
          <label className="checkBox" htmlFor="allCheck">
            전체 동의
          </label>
        </div>
        {/* 개인정보 동의 */}
        <div style={{ marginTop: "10px" }}>
          <label style={{ color: "red" }}>&#42;</label>
          <input
            className="checkBox"
            id="personalCheck"
            type="checkbox"
            checked={personalCheck}
            onChange={(e) => setPersonalCheck(e.target.checked)}
          ></input>
          <label className="checkBox" htmlFor="personalCheck">
            개인정보수집 및 이용동의
          </label>
          <a
            className="link-box"
            href="/personal"
            target="_blank"
            rel="noopener noreferrer"
          >
            보기
          </a>
        </div>
        {/* 이용약관 동의 */}
        <div style={{ marginTop: "10px" }}>
          <label style={{ color: "red" }}>&#42;</label>
          <input
            className="checkBox"
            id="termsCheck"
            type="checkbox"
            checked={termsCheck}
            onChange={(e) => setTermsCheck(e.target.checked)}
          ></input>
          <label className="checkBox" htmlFor="termsCheck">
            이용 약관
          </label>
          <a
            className="link-box"
            href="/terms"
            target="_blank"
            rel="noopener noreferrer"
          >
            보기
          </a>
        </div>
      </div>
      <button className="back-btn" onClick={handleBack}>
        &lt; 이전
      </button>
      <button className="next-btn" onClick={() => handleDataSend()}>
        다음 &gt;
      </button>
      {essential && (
        <p className="essential">&#8251; 모든 정보를 입력해주세요.</p>
      )}
      {essentialTerms && (
        <p className="essential">&#8251; 이용약관에 동의해주세요.</p>
      )}
    </div>
  );
};

export default Step2;
