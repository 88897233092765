import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { DonationPage, Terms, Personal } from "./pages/DonationPage";

export default function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<DonationPage />} />
        <Route path="/success/:step" element={<DonationPage />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/personal" element={<Personal />} />
      </Routes>
    </Router>
  );
}
